import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  // theme: {
  //   themes: {
  //     light: {
  //       primary: '#3f51b5',
  //       secondary: '#e91e63',
  //       accent: '#ff5722',
  //       error: '#f44336',
  //       warning: '#795548',
  //       info: '#ffeb3b',
  //       success: '#4caf50'
  //     }
  //   }
  // }
})
